
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.banner-links {
  .block__row {
    justify-content: flex-start;
    gap: 20px;
  }

  .block__col {
    width: calc(25% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: grab;
  }

  &__example {
    margin-bottom: $base-gutter;
  }

  &__settings {
    width: 100%;
    position: relative;
  }

  &__color-picker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    z-index: 1;
  }
}
